import { CircularProgress } from '@mui/material';
import {
  Box,
  DSComponent,
  Icon,
  Input,
  Text,
  useUserAction,
} from '@semper/shared-components';
import { ChangeEvent, ChangeEventHandler, Fragment, ReactNode } from 'react';

type Value = string;
export type RadioOption = {
  value: Value;
  label: ReactNode;
};

const Dot = ({
  active,
  error,
  sx,
}: { active: boolean; error?: boolean } & DSComponent) => (
  <Box
    sx={{
      borderRadius: '100%',
      border: '1px solid',
      borderColor: error ? 'error100' : active ? 'primary' : 'grey20',
      width: 24,
      height: 24,
      flexShrink: 0,
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }}
  >
    <Box
      sx={{
        backgroundColor: active ? 'primary' : 'transparent',
        borderRadius: '100%',
        width: '55%',
        height: '55%',
      }}
    />
  </Box>
);

export type RadioGroupProps = {
  name: string;
  options: RadioOption[];
  value?: Value;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;
};
export const RadioGroup = ({
  name,
  options,
  value,
  onChange,
  error,
  disabled,
  sx,
}: DSComponent & RadioGroupProps) => {
  const radioAction = useUserAction('radio-selected');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    radioAction({ name, value: e.target.value });
    onChange(e);
  };
  return (
    <Box
      as="fieldset"
      sx={{
        border: 0,
        margin: 0,
        gap: 3,
        ...sx,
      }}
    >
      {options.map(option => (
        <Box
          key={option.value}
          as="label"
          sx={{
            flexDirection: 'row',
            width: '100%',
            ...sx,
          }}
          _sx={{
            ':hover': {
              filter: disabled ? '' : 'brightness(90%)',
            },
            ':active': {
              filter: disabled ? '' : 'brightness(80%)',
            },
          }}
        >
          <Input
            type="radio"
            value={option.value}
            name={name}
            onChange={handleChange}
            disabled={disabled}
            sx={{
              opacity: 0,
              position: 'absolute',
              zIndex: -1,
            }}
          />
          <Dot active={value === option.value} error={error} />
          <Text sx={{ ml: 2 }}>{option.label}</Text>
        </Box>
      ))}
    </Box>
  );
};

export type CardOption = RadioOption & {
  sublabel?: ReactNode;
  cta?: ReactNode;
};
type CardProps = Omit<RadioGroupProps, 'options'> & {
  options: CardOption[];
  loading?: boolean;
};
export const RadioCard = ({
  name,
  options,
  value,
  onChange,
  disabled,
  loading,
  sx,
}: DSComponent & CardProps) => (
  <Box
    as="fieldset"
    sx={{
      border: 'solid',
      borderWidth: [0, 1],
      borderColor: 'grey10',
      bg: 'background',
      overflow: 'hidden',
      borderRadius: 12,
      p: 0,
      margin: 0,
      ...sx,
    }}
  >
    {options.map((option, i, _, isLast = i === options.length - 1) => (
      <Fragment key={option.value}>
        <Box
          as="label"
          sx={{
            position: 'relative',
            flexDirection: 'row',
            width: '100%',
            pt: 6,
            pb: option.cta ? 3 : 6,
            px: [0, 6],
            cursor: 'pointer',
            bg: 'background',
            ...sx,
          }}
          _sx={{
            ':hover': {
              filter: disabled ? '' : ['', 'brightness(98%)'],
            },
            ':active': {
              filter: disabled ? '' : 'brightness(96%)',
            },
          }}
        >
          <Input
            type="radio"
            value={option.value}
            name={name}
            onChange={onChange}
            disabled={disabled || loading}
            sx={{
              opacity: 0,
              position: 'absolute',
              zIndex: -1,
            }}
          />
          <Box sx={{ mr: 4, position: 'relative' }}>
            {loading && value === option.value ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '-6px',
                  left: '-6px',
                }}
              >
                <CircularProgress size={36} />
              </Box>
            ) : null}
            <Dot active={value === option.value} />
          </Box>
          <Box>
            <Text sx={{ fontWeight: 'medium' }}>{option.label}</Text>
            {option.sublabel ? (
              <Text variant="small" color="muted" sx={{ mt: 3 }}>
                {option.sublabel}
              </Text>
            ) : null}
          </Box>
          <Icon
            name="chevron"
            sx={{
              position: 'absolute',
              right: 24,
              transform: 'rotate(-90deg)',
              color: 'grey30',
              width: 16,
              mt: 1,
            }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            pl: [9, 16],
            pr: 6,
          }}
        >
          {option.cta}
          <Box
            sx={{
              borderBottom: isLast ? null : '1px solid',
              borderColor: 'grey10',
              pt: option.cta ? 3 : 0,
            }}
          />
        </Box>
      </Fragment>
    ))}
  </Box>
);
