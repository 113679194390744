import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ReactNode, useEffect, useMemo, useState } from 'react';

export const ApolloLayer = ({
  children,
  apiUrl,
}: {
  apiUrl: string;
  children: ReactNode;
}) => {
  const [error, setError] = useState<string | null>(null);

  const apolloClient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: apiUrl,
      credentials: 'include',
    });
    const errorLink = onError(
      ({ operation: { operationName }, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            setError(
              `[GraphQL error]: Operation: "${operationName}", Message: ${message}"`,
            );
          });
        }
        if (networkError) {
          setError(
            `[Network error]: Operation: "${operationName}", Error: "${networkError}"`,
          );
        }
      },
    );
    const apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        // Bit dirty but solves cache key issues
        dataIdFromObject: object => (object.nodeId || object.id) as string,
        typePolicies: {
          Currency: {
            fields: {
              amount: {
                read(val) {
                  return Number(val);
                },
              },
            },
          },
          LiquidityEvent: {
            fields: {
              ...[
                'sellerOpening',
                'sellerOfferDeadline',
                'sellerExecuteDeadline',
                'sellerClosing',
                'sellerPaymentDeadline',
                'buyerDataroomAccess',
                'buyerManagementCall',
                'buyerOrderDeadline',
              ].reduce(
                (acc, val) => ({
                  ...acc,
                  [val]: {
                    read(date: string) {
                      return new Date(date);
                    },
                  },
                }),
                {},
              ),
            },
          },
        },
      }),
      link: from([errorLink, httpLink]),
    });
    return apolloClient;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      // We throw an error here so it'll get caught by the <ErrorBoundary>
      throw new Error(error);
    }
  }, [error]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
