import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';
import { fontFamily, FontSize, palette, Spacing } from '../../theme';

export const ButtonSecondaryPadding = 16;

const StyledButton = styled.button`
  padding: ${ButtonSecondaryPadding}px;
  margin: -${ButtonSecondaryPadding}px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &:hover {
    color: ${palette.primary.dark};
  }

  color: ${palette.primary.main};
  font-size: ${FontSize.px14};
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-family: ${fontFamily};
`;

export type ButtonSecondaryProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
  showArrow?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export const ButtonSecondary = ({
  children,
  onClick,
  style = {},
  showArrow = false,
  type = 'button',
}: React.PropsWithChildren<ButtonSecondaryProps>) => {
  return (
    <StyledButton
      style={{ display: 'flex', alignItems: 'center', ...style }}
      type={type}
      onClick={onClick}
    >
      {children}
      {showArrow && (
        <div style={{ marginLeft: Spacing.px24, lineHeight: 0 }}>
          <ArrowForward fontSize="small" />
        </div>
      )}
    </StyledButton>
  );
};
