import styled from '@emotion/styled';
import React from 'react';
import { palette } from '../../theme';

const StyledA = styled.a`
  text-decoration: none;
  color: ${palette.primary.main} !important;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:visited {
    color: unset;
  }
  &:hover {
    color: ${palette.primary.dark} !important;
  }
  &:active {
    color: ${palette.primary.main} !important;
  }
`;

export const StyledLink = ({
  children,
  ...otherProps
}: {
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <StyledA {...otherProps}>{children}</StyledA>;
};
