import { Box, Typography } from '@mui/material';
import {
  ButtonSecondary,
  palette,
  SemperIcon,
  SemperIconType,
  Spacing,
  ZIndex,
} from '@semper/shared-components';
import { NotificationType, useNotification } from './';

export const Notification = ({
  type,
  message,
  onClose,
}: NotificationType & {
  onClose?: () => void;
}) => {
  const styles: Record<NotificationType['type'], [string, string]> = {
    success: [palette.success.main, palette.success.light],
    error: [palette.error.main, palette.error.light],
  };
  const [fg, bg] = styles[type];

  return (
    <Box
      position="fixed"
      top={0}
      left="50%"
      zIndex={ZIndex.AlwaysVisible}
      sx={{
        transform: 'translateX(-50%)',
      }}
    >
      <Box
        py={Spacing.px16}
        px={Spacing.px96}
        borderRadius={`0 0 ${Spacing.px16} ${Spacing.px16}`}
        textAlign="center"
        bgcolor={bg}
        position="relative"
      >
        <Typography flex="1" color={fg}>
          {message}
        </Typography>
        {onClose ? (
          <ButtonSecondary
            // TODO: Refactor buttons to use system props
            style={{
              display: 'flex',
              position: 'absolute',
              top: Spacing.px16,
              right: Spacing.px16,
              padding: Spacing.px16,
            }}
            onClick={onClose}
          >
            <SemperIcon type={SemperIconType.XCross} color={fg} />
          </ButtonSecondary>
        ) : null}
      </Box>
    </Box>
  );
};

export const Notifications = () => {
  // TODO: Support multiple simultaneous notifications
  const { notification, clearNotification } = useNotification();
  return notification ? (
    <Notification {...notification} onClose={clearNotification} />
  ) : null;
};
