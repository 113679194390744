import { SemperCookie } from '@semper/rust/lib/core/pkg-web';
import {
  AuthPaths,
  BiometricsIcon,
  Box,
  Button,
  FieldContainer,
  Icon,
  Text,
  useNotification,
  useURLs,
  useVariables,
} from '@semper/shared-components';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWebAuthn } from '..';

export const AuthContext = createContext<{
  loggedIn: boolean;
  email: string;
  webAuthnRegisteredOnDevice: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  setEmail: (email: string) => void;
  setWebAuthnRegisteredOnDevice: (state: boolean) => void;
  changingPassword: boolean;
  setChangingPassword: (state: boolean) => void;
}>({
  loggedIn: false,
  webAuthnRegisteredOnDevice: false,
  email: '',
  setLoggedIn: () => null,
  setEmail: () => null,
  setWebAuthnRegisteredOnDevice: () => null,
  changingPassword: false,
  setChangingPassword: () => null,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState(!!SemperCookie.load());
  const [email, setEmail] = useState(SemperCookie.load()?.email || '');
  const [changingPassword, setChangingPassword] = useState(
    SemperCookie.load()?.requesting_change_to_auth || false,
  );
  // TODO: Move this or something so we can make use of it elsewhere
  const [webAuthnRegisteredOnDevice, setWebAuthnRegisteredOnDevice] =
    useState(false);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        email,
        setEmail,
        webAuthnRegisteredOnDevice,
        setWebAuthnRegisteredOnDevice,
        changingPassword,
        setChangingPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const [error, setError] = useState<Error | null>(null);
  const { setLoggedIn, setEmail } = useAuthContext();
  const { auth_api_url } = useVariables();

  const {
    webAuthnRegisteredOnDevice,
    canRegisterBiometric,
    registerWebauthn,
    webAuthnLogin,
    checkWebAuthn,
    loading: loadingWebAuthn,
  } = useWebAuthn(auth_api_url);
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get('email');
  const semperCookie = SemperCookie.load();
  const navigate = useNavigate();

  const changingPassword = !!semperCookie?.requesting_change_to_auth;
  const hasPassword = semperCookie?.has_password;
  const userId = semperCookie?.user_id;
  const redirectPath = semperCookie?.redirect_path;
  const webAuthnRegistered = !!semperCookie?.webauthn_registered;

  const onLogin = () => {
    setLoggedIn(true);
    if (changingPassword) {
      navigate(AuthPaths.setPassword);
    }
  };
  const onLogout = () => {
    setLoggedIn(false);
    SemperCookie.logout();
  };

  const checkPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) =>
    fetch(`${auth_api_url}/password`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email, password }),
    }).catch(setError);

  const sendMagicLink = async ({ email }: { email: string }) =>
    fetch(`${auth_api_url}/send-magic-link`, {
      method: 'POST',
      body: JSON.stringify({ email }),
    }).catch(setError);

  const validateMagicLink = async ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }) => {
    const res = await fetch(`${auth_api_url}/verify-magic-link`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email, code: String(code) }),
    }).catch(setError);

    if (res?.ok) {
      return res.text();
    } else {
      return false;
    }
  };

  const registerPassword = async ({ password }: { password: string }) => {
    const res = await fetch(`${auth_api_url}/register-password`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ password }),
    });
    if (res.ok) {
      return res.text();
    } else {
      return false;
    }
  };

  const registerUser = async ({
    email,
    shortName,
    fullName,
    password,
  }: {
    shortName: string;
    fullName: string;
    email: string;
    password: string;
  }) =>
    fetch(`${auth_api_url}/register-user`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        short_name: shortName,
        full_name: fullName,
        password,
      }),
    }).catch(setError);

  useEffect(() => {
    if (paramEmail) setEmail(paramEmail);
  }, [paramEmail]);

  useEffect(() => {
    if (error) {
      // We throw an error here so it'll get caught by the <ErrorBoundary>
      throw error;
    }
  }, [error]);

  return {
    changingPassword,
    semperCookie,
    webAuthnRegisteredOnDevice,
    canRegisterBiometric,
    hasPassword,
    onLogin,
    onLogout,
    registerWebauthn,
    webAuthnLogin,
    registerPassword,
    registerUser,
    checkPassword,
    sendMagicLink,
    validateMagicLink,
    userId,
    redirectPath,
    webAuthnRegistered,
    loadingWebAuthn,
    checkWebAuthn,
  };
};

export const AuthUserOptions = () => {
  const { canRegisterBiometric, hasPassword, checkWebAuthn, registerWebauthn } =
    useAuth();
  const { AuthURLs } = useURLs(true);
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');
  const { notifySuccess } = useNotification();
  const [registering, setRegistering] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleBiometricRegister = async () => {
    setError(null);
    setRegistering(true);
    try {
      await registerWebauthn();
      setRegistering(false);
      notifySuccess('Biometrics are now set up!');
    } catch (e) {
      console.warn('Failed', e);
      if ((e as Error).message.startsWith('Failed DOMException')) {
        return;
      }
      setError(e as Error);
    }
    setRegistering(false);
  };

  useEffect(() => {
    if (success) {
      notifySuccess('Your details have been updated!');
      searchParams.delete('success');
    }
  }, [success, notifySuccess]);

  useEffect(() => {
    checkWebAuthn();
  }, []);

  return (
    <FieldContainer
      sx={{
        alignItems: 'flex-start',
      }}
    >
      {hasPassword ? (
        <Button
          variant="secondary"
          sx={{ maxWidth: 560 }}
          onClick={() => {
            window.location.href = `${
              AuthURLs.changePassword
            }?redirect=${encodeURIComponent(window.location.href)}`;
          }}
        >
          Change Password
        </Button>
      ) : (
        <Button
          variant="secondary"
          sx={{ maxWidth: 560 }}
          icon={<Icon name="emailPassword" />}
          onClick={() => {
            window.location.href = `${
              AuthURLs.setPassword
            }?redirect=${encodeURIComponent(window.location.href)}`;
          }}
        >
          Set a password
        </Button>
      )}
      {canRegisterBiometric && (
        <Button
          onClick={handleBiometricRegister}
          loading={registering}
          icon={<BiometricsIcon />}
          variant="secondary"
          sx={{ maxWidth: 560 }}
        >
          Set up biometrics
        </Button>
      )}
      {error ? (
        <Box sx={{ my: 6 }}>
          <Text variant="error">
            Uh oh! Looks like something went wrong. Please try again.
          </Text>
        </Box>
      ) : null}
    </FieldContainer>
  );
};
