const suffix = ['', 'K', 'M', 'B', 'T'];
export const humanFormat = (value: number) => {
  const suffixIndex = Math.floor(Math.log(value) / Math.log(1000));
  const number = value / Math.pow(1000, suffixIndex);
  return `${parseFloat(number.toFixed(1))}${suffix[suffixIndex]}`;
};

humanFormat.parse = (value: string): number => {
  const valueSuffix = value
    .split('')
    .map(char => suffix.findIndex(suffix => suffix === char.toUpperCase()))
    .filter(x => x !== -1);

  const strippedValue = value.replace(/[^\d^.]/g, '');

  if (valueSuffix.length === 0) {
    return parseFloat(strippedValue);
  } else if (valueSuffix.length === 1) {
    return parseFloat(strippedValue) * Math.pow(1000, valueSuffix[0]);
  } else {
    // This is an invalid value, set to 0
    return 0;
  }
};
