import { Box, DSComponent } from '@semper/shared-components';

export const Constrain = ({ sx, ...rest }: DSComponent) => (
  <Box
    sx={{
      maxWidth: 1136,
      ...sx,
    }}
    {...rest}
  />
);

export const PageBox = ({ sx, ...rest }: DSComponent) => (
  <Constrain
    sx={{
      m: 1,
      px: 6,
      py: [6, 8, 14, 16],
      width: '100%',
      ...sx,
    }}
    {...rest}
  />
);
