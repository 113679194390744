import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { isNaN } from 'formik';
import React, {
  forwardRef,
  Ref,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { humanFormat } from './human-format';

const Styles = styled.div<{
  fontSize: string;
  fontWeight: string;
  color: string;
  editable: boolean;
}>`
  display: inline-flex;
  font-weight: ${p => p.fontWeight};
  font-size: ${p => p.fontSize};
  color: ${p => p.color};
  justify-content: center;

  ${p =>
    p.editable &&
    `
    cursor: text;
  `}
`;

const TextInput = styled.input<{
  widthPx: number;
  color: string;
}>`
  all: unset;
  margin-left: 4px;
  margin-right: 4px;
  width: ${p => Math.max(p.widthPx, 10)}px;

  ::placeholder {
    opacity: 0.5;
    color: ${p => p.color};
  }
`;

const HiddenHolder = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: -1;
  left: -40000px;
  font-stretch 100%
`;

const splitNumberIntoThrees = (inputString: string) => {
  const output = [];
  const hasPeriod = inputString.indexOf('.') !== -1;

  const beforePeriod = inputString.split('.')[0];
  const afterPeriod = inputString.split('.')[1];
  const digts = beforePeriod.split('');
  let counter = 1;
  while (digts.length > 0) {
    output.push(digts.pop());
    if (counter === 3 && digts.length > 0) {
      counter = 1;
      output.push(',');
    } else {
      counter += 1;
    }
  }
  return output.reverse().join('') + (hasPeriod ? '.' + afterPeriod : '');
};
const getFormattedValue = (localValue: string) =>
  localValue ? splitNumberIntoThrees(localValue.toString()) : '';
const getWidth = (hiddenRef: RefObject<HTMLDivElement>, value: string) => {
  const hiddenHolder = hiddenRef.current as HTMLDivElement;
  hiddenHolder.innerText = value;
  return hiddenHolder.offsetWidth;
};

const getNewCursorPosition = ({
  oldString,
  newString,
  oldCursorPosition,
}: {
  oldString: string;
  newString: string;
  oldCursorPosition: number;
}) => {
  const changeInLength = newString.length - oldString.length;
  const addingCharacter = changeInLength > 0;

  if (addingCharacter) {
    return oldCursorPosition + changeInLength - 1;
  } else {
    if (oldCursorPosition === 0) {
      return 0;
    } else {
      return oldCursorPosition + changeInLength + 1;
    }
  }
};

type Props = {
  value?: number;
  editable?: boolean;
  fontWeight: string;
  fontSize: string;
  color: string;
  symbolBefore?: React.ReactNode;
  symbolAfter?: React.ReactNode;
  className?: string;
  onBlur?: (v: number) => void;
  onChange?: (v: number) => void;
  summaryFormatter?: (v: string) => string;
};

const ResponsiveAmountComponent = (
  {
    value,
    symbolBefore,
    symbolAfter,
    fontWeight,
    fontSize,
    color,
    editable = true,
    onBlur = () => undefined,
    onChange = () => undefined,
    summaryFormatter = (value: string) => value,
  }: Props,
  ref: Ref<{
    focus: () => void;
  }>,
) => {
  const [localValue, setLocalValue] = useState('');

  const formattedValue = getFormattedValue(localValue);
  const [active, setActive] = useState(false);
  const displayedValue = active
    ? formattedValue
    : summaryFormatter(formattedValue);

  // For getting the size of the text to resize the input box
  const getTextWidthHiddenElement = useRef<HTMLDivElement>(null);
  const [textWidth, setTextWidth] = useState(10);

  // To put the cursor in the right place after editing
  const [cursorIndex, setCursorIndex] = useState(formattedValue?.length ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const handleContainerClick = () =>
    inputRef.current && inputRef?.current.focus();

  useEffect(() => {
    if (inputRef.current) {
      // Setting a selection on iOS will focus that input; and without this
      // return, the input is auto-focused on mount (which we don't want).
      if (cursorIndex < 1) return;

      const inputBox = inputRef.current;
      inputBox.selectionStart = cursorIndex;
      inputBox.selectionEnd = cursorIndex;
    }
  }, [cursorIndex, inputRef]);
  useEffect(() => {
    // The dot is weird, but there is a placeholder bug that unless you do it that way
    // the width is not calculated correctly.
    setTextWidth(
      getWidth(
        getTextWidthHiddenElement,
        displayedValue !== '' ? displayedValue : '0.',
      ),
    );
  }, [displayedValue]);
  useEffect(() => {
    setLocalValue(value ? value.toString() : '');
  }, [value, setLocalValue]);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      onClick={handleContainerClick}
    >
      <Styles
        fontWeight={fontWeight}
        fontSize={fontSize}
        color={color}
        editable={editable}
      >
        <HiddenHolder ref={getTextWidthHiddenElement} />

        {symbolBefore}

        <TextInput
          ref={inputRef}
          value={displayedValue}
          color={color}
          disabled={!editable}
          widthPx={textWidth}
          size={0}
          placeholder="0"
          onChange={e => {
            const textBoxContents = e.target.value;
            const newValue = textBoxContents.replace(/[^\d^.]/g, '');

            const newString = getFormattedValue(newValue);
            const oldCursorPosition =
              e.target.selectionStart ?? newString.length;
            const newCursorPosition = getNewCursorPosition({
              oldString: formattedValue,
              newString,
              oldCursorPosition,
            });
            setLocalValue(newValue);
            setCursorIndex(newCursorPosition);
            onChange(humanFormat.parse(newValue));
          }}
          onFocus={() => {
            setActive(true);
          }}
          onBlur={() => {
            setActive(false);
            onBlur(humanFormat.parse(localValue));
          }}
        />
        {symbolAfter}
      </Styles>
    </Box>
  );
};
export const ResponsiveAmount = forwardRef(ResponsiveAmountComponent);
export default ResponsiveAmount;

export const ResponsiveAmountFormatters = {
  humanFormat: (x: string) => {
    if (!x) {
      return x;
    }
    const strippedNumber = Number(x.replace(/[^0-9]/g, ''));
    if (isNaN(strippedNumber)) {
      return x;
    } else {
      return humanFormat(strippedNumber);
    }
  },
};

// These are used in the storybook and tests
export const ResponsiveAmountExampleArgs: Props = {
  value: 100000,
  fontSize: '20px',
  color: 'black',
  fontWeight: '400',
  symbolBefore: '$',
  symbolAfter: 'USD',
  // eslint-disable-next-line no-console
  onBlur: console.log,
  summaryFormatter: ResponsiveAmountFormatters.humanFormat,
};
