import { makeContext } from '../makeContext';

export enum AnimationDuration {
  VeryShort = 200,
  Short = 300,
  Normal = 500,
  Long = 700,
  ExtraLong = 1000,
}

export type AnimationEasing =
  | 'linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | string;

export const defaultDelayRatio = 0.5;
export const defaultDuration = AnimationDuration.Short;
export const defaultEasing: AnimationEasing = 'ease-in-out';

export type AnimationDelayProp =
  | {
      delayAsPreviousDurationRatio?: number;
    }
  | {
      delayInMs: number;
    };

export type AnimationProps = {
  id: string;
  playedAfterId?: string;
  durationInMs?: number;
  includeInFlow?: boolean;
  easing?: AnimationEasing;
  onStart?: () => void;
  onRestart?: () => void;
  onEnd?: () => void;
} & AnimationDelayProp;

export type AnimationResult = {
  delayInMs: number;
  durationInMs: number;
  started: boolean;
  ended: boolean;
  startsIn: number;
  endsIn: number;
};

export interface AnimationContext {
  addAnimation: (animationProps: AnimationProps) => void;
  removeAnimation: (id: string) => void;
  play: () => void;
}

export const {
  useContext: useAnimationContext,
  Provider: AnimationContextProvider,
} = makeContext<AnimationContext>('AnimationContext');
