import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { SemperCookie } from '@semper/rust/lib/core/pkg-web';
import { LoadingLayoutPage } from '@semper/shared-components';
import React, { createContext, useContext, useEffect } from 'react';
import { useCurrentUserQuery } from '../generated/graphql';

gql`
  query CurrentUser {
    loggedInUser {
      user {
        id
        shortName
        fullName
        email
      }
      team {
        id
        allowedToViewDeals
        buyerOnboarding {
          buyerOnboardingCompleted
        }
        sellerOnboarding {
          sellerOnboardingCompleted
        }
        teamName
      }
    }
    currentUser {
      email
      fullName
      shortName
      teamId
      teamName
      allowedToViewDeals
      buyerOnboardingCompleted
      sellerOnboardingCompleted
      userId
      hasBuyerOnboarding
      hasSellerOnboarding
    }
    liquidityEvents {
      nodes {
        id
      }
    }
  }
`;

const initCurrentUser = {
  email: '',
  fullName: '',
  shortName: '',
  teamName: '',
  teamId: '',
  userId: '',
  allowedToViewDeals: false,
  buyerOnboardingCompleted: false,
  sellerOnboardingCompleted: false,
  refetch: () => null,
  liquidityEvents: null,
  hasBuyerOnboarding: false,
  hasSellerOnboarding: false,
  currentLiquidityEventId: '',
};
const CurrentUserContext = createContext<{
  email: string;
  fullName: string;
  shortName: string;
  teamName?: string;
  teamId: string;
  userId: string;
  allowedToViewDeals: boolean;
  buyerOnboardingCompleted: boolean;
  sellerOnboardingCompleted: boolean;
  refetch: () => void;
  liquidityEvents: { id: string }[] | null;
  hasBuyerOnboarding: boolean;
  hasSellerOnboarding: boolean;
  currentLiquidityEventId: string;
}>(initCurrentUser);

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({
  children,
  refreshIfNoUser = true,
}: {
  children: React.ReactNode;
  refreshIfNoUser?: boolean;
}) => {
  const { loading, data, refetch } = useCurrentUserQuery();

  useEffect(() => {
    if (!data?.loggedInUser?.user) return;
    datadogRum.setUser({
      id: data.loggedInUser.team!.id!,
      name: data.loggedInUser.user.fullName!,
      email: data.loggedInUser.user.email!,
    });
  }, [loading, data]);

  if (loading) {
    return <LoadingLayoutPage />;
  }

  if (!data?.loggedInUser && refreshIfNoUser) {
    SemperCookie.logout();
    window.location.reload();
    return null;
  }

  const liquidityEvents = (data?.liquidityEvents?.nodes || []).map(
    ({ id }) => ({
      id,
    }),
  );
  const currentLiquidityEventId = data?.liquidityEvents?.nodes[0]?.id || '';

  return (
    <CurrentUserContext.Provider
      value={{
        fullName: data?.loggedInUser?.user?.fullName || '',
        shortName: data?.loggedInUser?.user?.shortName || '',
        teamName: data?.loggedInUser?.team?.teamName || '',
        teamId: data?.loggedInUser?.team?.id || '',
        userId: data?.loggedInUser?.user?.id || '',
        email: data?.loggedInUser?.user?.email || '',
        allowedToViewDeals:
          data?.loggedInUser?.team?.allowedToViewDeals || false,
        buyerOnboardingCompleted:
          data?.loggedInUser?.team?.buyerOnboarding?.buyerOnboardingCompleted ||
          false,
        sellerOnboardingCompleted:
          data?.loggedInUser?.team?.sellerOnboarding
            ?.sellerOnboardingCompleted || false,
        hasBuyerOnboarding: !!data?.loggedInUser?.team?.buyerOnboarding,
        hasSellerOnboarding: !!data?.loggedInUser?.team?.sellerOnboarding,
        liquidityEvents,
        currentLiquidityEventId,
        refetch,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
