import { ErrorBoundary as DatadogErrorBoundary } from '@Datadog/rum-react-integration';
import { ReactNode, useState } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Box } from '../Box';
import { Button } from '../button';
import { Constrain } from '../Constrain';
import { Logo } from '../Icon';
import { Text } from '../Text';

export const ErrorPageLayout = ({
  title,
  message,
  error,
}: {
  title?: string;
  message?: string;
  error?: Error;
}) => {
  if (!title) {
    title = 'Uh oh, there’s been an unexpected error';
    message = 'Something went wrong. Our team is on it!';
  }

  const isNetworkError = error?.message?.match(/Failed to fetch/);

  if (isNetworkError) {
    title = 'We’re having network difficulties';
    message =
      'Looks like we’re struggling to connect. Please refresh and try again.';
  }

  const [open, setOpen] = useState(false);

  return (
    <Constrain sx={{ px: 5 }} _sx={{ margin: '0 auto' }}>
      <Box sx={{ alignItems: 'center' }}>
        <Logo sx={{ py: 5 }} />
      </Box>
      <Box
        sx={{
          flexDirection: ['column', 'row'],
          alignItems: 'center',
          justifyContent: 'space-evenly',
          minHeight: open ? '50vh' : '100vh',
        }}
      >
        <Box
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
          }}
        >
          <Text sx={{ m: 4, fontSize: 6 }}>⚠️</Text>
          <Box sx={{ gap: 3 }}>
            <Text variant="title">{title}</Text>
            {message && <Text>{message}</Text>}
            {error && error.message && (
              <Button
                size="tiny"
                variant="secondary"
                onClick={() => setOpen(!open)}
              >
                Show technical details
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {error && open ? (
        <>
          <code
            style={{
              width: '100%',
              overflow: 'scroll',
            }}
          >
            <pre>{error.name}</pre>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
          </code>
        </>
      ) : null}
    </Constrain>
  );
};

export const RouteErrorPage = () => {
  const error = useRouteError();
  const title = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : undefined;

  return <ErrorPageLayout title={title} error={error as Error} />;
};

export const ErrorBoundary = ({ children }: { children: ReactNode }) => (
  // @ts-expect-error DD's component missing children prop
  <DatadogErrorBoundary fallback={error => <ErrorPageLayout error={error} />}>
    {children}
  </DatadogErrorBoundary>
);
