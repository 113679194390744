/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  NotificationProvider,
  Notifications,
  SemperLogo,
} from '@semper/shared-components';
import { Outlet } from 'react-router-dom';

export const RootPage = () => (
  <NotificationProvider>
    <Notifications />
    <Box
      sx={{
        flexDirection: 'column',
        bg: 'background',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box sx={{ my: 8 }}>
        <SemperLogo />
      </Box>
      <Box
        sx={{
          flexDirection: 'column',
          minWidth: 300,
          width: '100%',
          maxWidth: 416,
          height: '100%',
          justifyContent: 'center',
          p: 6,
          mt: [0, 8, 16],
          bg: 'background',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  </NotificationProvider>
);
