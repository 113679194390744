import {
  AnimatedBox,
  Box,
  DSComponent,
  Icon,
  Input,
  SxType,
  Text,
  useUserAction,
} from '@semper/shared-components';
import { ChangeEvent, ReactNode } from 'react';
import { useSpring } from 'react-spring';

export const Checkbox = ({
  sx,
  label,
  name,
  value,
  checked,
  disabled,
  error,
  onChange,
  labelSx,
  ...rest
}: DSComponent & {
  label: ReactNode;
  value: string | boolean;
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  name: string;
  labelSx?: SxType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const config = { mass: 1, tension: 250, friction: 15 };
  const boxStyle = useSpring({
    opacity: checked ? 1 : 0,
    config,
  });
  const iconStyle = useSpring({
    opacity: checked ? 1 : 0,
    transform: `rotate(${checked ? 0 : 70}deg) scale(${checked ? 1 : 0.5})`,
    config,
  });
  const checkedAction = useUserAction('checked-box');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      checkedAction({ name, value: `${value}` });
    }
    onChange(e);
  };

  return (
    <Box
      as="label"
      sx={{
        flexDirection: 'row',
        width: '100%',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? null : 'pointer',
        ...sx,
      }}
      _sx={{
        ':hover': {
          filter: disabled ? '' : 'brightness(95%)',
        },
        ':active': {
          filter: disabled ? '' : 'brightness(80%)',
        },
      }}
    >
      <Input
        type="checkbox"
        value={value.toString()}
        name={name}
        sx={{
          opacity: 0,
          position: 'absolute',
          zIndex: -1,
        }}
        onChange={disabled ? () => undefined : handleChange}
        {...rest}
      />
      <Box
        _sx={{
          'input:focus ~ &': {
            outline: 'thick double',
            outlineColor: 'primary',
          },
        }}
        sx={{
          flexShrink: 0,
          width: 24,
          height: 24,
          borderRadius: 6,
          border: '1px solid',
          borderColor: error ? 'error100' : checked ? 'primary' : 'grey20',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <AnimatedBox
          sx={{
            bg: 'primary',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          style={boxStyle}
        />

        <AnimatedBox style={iconStyle}>
          <Icon
            name="check"
            sx={{
              color: 'white',
            }}
          />
        </AnimatedBox>
      </Box>
      <Text sx={{ ml: 2, color: error ? 'error100' : 'text', ...labelSx }}>
        {label}
      </Text>
    </Box>
  );
};
