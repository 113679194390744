import { makeContext } from '../makeContext';

export type RouterContext = {
  lastUri: string | null;
  currentUri: string | null;
  lastNonSettingsUri: string | null;
};

export const { useContext: useRouterContext, Provider: RouterContextProvider } =
  makeContext<RouterContext>('RouterContext');
