import { useRumAction } from '@Datadog/rum-react-integration';
export const useUserAction = (event: string) => {
  const action = useRumAction('userAction');

  const handler = (customAttributes?: Record<string, string>) => {
    action(event, customAttributes);
  };

  return handler;
};
