import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloLayer, theme } from '@semper/shared-components';
import { HelmetProvider } from 'react-helmet-async';

import { datadogRum } from '@datadog/browser-rum';
import init, { Variables } from '@semper/rust/lib/core/pkg-web';
import { ErrorBoundary } from '@semper/shared-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from './Router';

const load = async () => {
  if (typeof process === 'undefined') {
    // Only code that is running in the browser needs to have init() called.
    await init();
  }

  const { mode, postgraphile_api } = Variables.load_core(
    import.meta.env.VITE_MODE,
    import.meta.env.VITE_BRANCH,
  );

  datadogRum.init({
    applicationId: '4f03a088-f8ab-4417-8dde-ee341694e598',
    clientToken: 'puba2fbd413ed70553bfc588fdf428d1267',
    site: 'datadoghq.eu',
    service: 'explore',
    env: mode,
    version: import.meta.env.VITE_RELEASE_VERSION || mode,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <ApolloLayer apiUrl={postgraphile_api}>
                <Router />
              </ApolloLayer>
            </CssBaseline>
          </ThemeProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.StrictMode>,
  );
};

load();
