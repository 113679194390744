import {
  Box,
  DSComponent,
  Icon,
  IconNames,
  SxType,
  _Select,
} from '@semper/shared-components';
import { SelectHTMLAttributes } from 'react';

type Option = {
  value: string | number;
  label: string;
};

export type SelectProps = DSComponent & {
  options: Option[];
  placeholder: string;
  size?: 'small' | 'normal';
  value?: string;
  error?: boolean;
  startIconName?: IconNames;
  selectSx?: SxType;
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'>;

export const Select = ({
  options,
  onChange,
  value,
  placeholder = 'Select an option',
  error,
  disabled,
  startIconName,
  sx,
  size,
  selectSx,
  ...rest
}: SelectProps) => {
  const isSmall = size === 'small';
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        ...sx,
      }}
    >
      <_Select
        // TODO: add `aria-label` with a proper label name
        value={value}
        onChange={onChange}
        disabled={disabled}
        sx={{
          display: 'block',
          appearance: 'none',
          border: '1px solid',
          borderColor: error ? 'error100' : 'grey20',
          borderRadius: 12,
          bg: 'background',
          py: isSmall ? 3 : 5,
          pl: startIconName ? 10 : 5,
          pr: 9,
          color: value === '' ? 'grey50' : 'text',
          outlineColor: 'primary',
          fontSize: isSmall ? 0 : 2,
          ...selectSx,
        }}
        _sx={{
          fontFamily: "'Aeonik', sans-serif",
        }}
        {...rest}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </_Select>
      {startIconName ? (
        <Box
          sx={{
            width: 16,
            position: 'absolute',
            left: 4,
            top: isSmall ? '13px' : '24px',
            pointerEvents: 'none',
          }}
        >
          <Icon
            sx={{
              color: 'grey50',
              width: '100%',
            }}
            name={startIconName}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          width: isSmall ? '14px' : '16px',
          position: 'absolute',
          right: 4,
          top: isSmall ? '13px' : '24px',
          pointerEvents: 'none',
        }}
      >
        <Icon
          sx={{
            color: 'grey50',
            width: '100%',
          }}
          name="chevron"
        />
      </Box>
    </Box>
  );
};
