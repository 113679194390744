import styled from '@emotion/styled';
import { useBackgroundColorContext } from '../../context';
import { palette } from '../../theme';
import { RoundedDiv } from '../container/RoundedDiv';
import { SemperIcon, SemperIconType } from '../graphics/SemperIcon';
import { SettingsButtonIcon } from '../graphics/SettingsButtonIcon';
import { PageColorVariant } from '../layout/PageColorVariant';

const StyledDiv = styled.div`
  cursor: pointer;

  .rounded-container {
    display: flex;
    border-radius: 25px;
    padding: 6px;
    align-items: center;
    width: fit-content;
    height: 32px;
    background-color: transparent;

    &.dark {
      border: 1px solid rgba(255, 255, 255, 0.25);
      color: ${palette.common.white};
      .hamburger svg path {
        fill: ${palette.common.white};
      }
    }
  }
  .icon-container {
    line-height: 16px;
  }
`;

export type LoggedMenuProps = {
  onClick?: () => void;
  pageColorVariant?: PageColorVariant;
};

export const LoggedMenu = ({ onClick }: LoggedMenuProps) => {
  const { pageColorVariant } = useBackgroundColorContext();

  return (
    <StyledDiv aria-label="settings">
      <RoundedDiv
        className={`rounded-container ${pageColorVariant}`}
        onClick={onClick}
        style={{ cursor: 'unset' }}
      >
        <div
          style={{ marginRight: '12px', display: 'flex' }}
          className="hamburger"
        >
          <SemperIcon
            type={SemperIconType.MenuHamburger}
            size={16}
            color={palette.text.secondary}
          />
        </div>
        <div className="icon-container">
          <SettingsButtonIcon />
        </div>
      </RoundedDiv>
    </StyledDiv>
  );
};
