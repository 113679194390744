import { makeContext } from '../makeContext';
import { isWidthSmallerThan } from './responsiveHelpers';

export enum WidthType {
  Mobile = 'mobile',
  Medium = 'tablet',
  Normal = 'normal',
}

export const widthTypeFromWidth = (width: number) => {
  const sizes = [
    { type: WidthType.Mobile, width: 768 },
    { type: WidthType.Medium, width: 1136 },
  ];
  const foundSize = sizes.find(size => size.width >= width);
  return foundSize?.type ?? WidthType.Normal;
};

export type ViewportContext = {
  width: number;
  height: number;
  widthType: WidthType;
  isMobile: boolean;
  isSmallerThanMedium: boolean;
  isSmallerThanNormal: boolean;
};

export const { useContext: useViewportContext, Provider } =
  makeContext<ViewportContext>('ViewportContext');

export const ViewportContextProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: { width: number; height: number };
}) => {
  const { width, height } = value;
  const widthType = widthTypeFromWidth(width);

  return (
    <Provider
      value={{
        width,
        height,
        widthType,
        isMobile: isWidthSmallerThan(widthType, WidthType.Mobile),
        isSmallerThanMedium: isWidthSmallerThan(widthType, WidthType.Medium),
        isSmallerThanNormal: isWidthSmallerThan(widthType, WidthType.Normal),
      }}
    >
      {children}
    </Provider>
  );
};
