import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { BorderRadius, palette, Spacing } from '../../theme';

const StyledDiv = styled.div`
  border: 1px solid ${palette.grey.A200};
  background-color: ${palette.common.white};
  margin: 0;
  padding: ${Spacing.px16};
  border-radius: ${BorderRadius.px12};
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
`;

const StyledButton = styled.button`
  background-color: ${palette.common.white};
  margin: 0;
  padding: ${Spacing.px16};
  border-radius: ${BorderRadius.px12};
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;

  border: 1px solid ${palette.grey.A200};
  text-align: left;
  width: 100%;
  &:hover {
    border-color: ${palette.text.disabled};
  }
`;

export const RoundedDiv = ({
  children,
  style,
  className,
  onClick,
  isSelected,
}: {
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  isSelected?: boolean;
}) => {
  const finalStyle: CSSProperties = {
    cursor: onClick ? 'pointer' : 'auto',
    ...style,
    ...(isSelected ? { borderColor: palette.primary.main } : {}),
  };
  return onClick ? (
    <StyledButton
      onClick={onClick}
      onKeyPress={onClick}
      style={finalStyle}
      className={className}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledDiv style={finalStyle} className={className}>
      {children}
    </StyledDiv>
  );
};
