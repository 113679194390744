import { RouteErrorPage } from '@semper/shared-components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RootPage } from './RootPage';

export const Router = () => {
  const router = createBrowserRouter([
    {
      element: <RootPage />,
      errorElement: <RouteErrorPage />,
      children: [
        {
          path: '*',
          element: <h1>placeholder...</h1>,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};
