import { CircularProgress } from '@mui/material';
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { Anchor, Box, BoxLink, Touchable } from '../Box';
import { Icon, IconNames } from '../Icon';
import { DSComponent, SxType } from '../SemperDS';
import { Text } from '../Text';

type Variants = 'primary' | 'secondary' | 'tertiary' | 'simple' | 'icon';
type Sizes = 'tiny' | 'small' | 'normal';

const variants: Record<Variants, SxType> = {
  primary: {
    backgroundColor: 'primary',
  },
  secondary: {
    color: 'primary',
    backgroundColor: 'background',
    border: '1px solid',
    borderColor: 'grey20',
  },
  tertiary: {
    color: 'primary',
    backgroundColor: 'background',
  },
  simple: {
    p: 0,
    color: 'primary',
    bg: 'transparent',
    borderRadius: 0,
    width: 'fit-content',
  },
  icon: {
    backgroundColor: 'background',
    color: 'grey50',
    borderRadius: '100%',
    border: '1px solid',
    borderColor: 'grey20',
    width: 32,
    height: 32,
    p: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export const __variantsForStorybook = Object.keys(variants) as Variants[];

const sizes: Record<Sizes, SxType> = {
  tiny: {
    width: 'fit-content',
    borderRadius: 6,
    p: 2,
    fontSize: 0,
  },
  small: {
    px: 4,
    py: 3,
    fontSize: 0,
    borderRadius: 8,
    width: 'fit-content',
  },
  normal: {
    px: 4,
    py: 5,
    borderRadius: 12,
    fontSize: 1,
  },
};

type ButtonAllowList = Pick<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type' | 'disabled'
>;
type OnClickProp = {
  onClick: () => void;
  to?: never;
  href?: never;
};
type ToProp = {
  to: string;
  href?: never;
  onClick?: never;
};
type HrefProp = {
  href: string;
  onClick?: never;
  to?: never;
};
type ClickProps = OnClickProp | ToProp | HrefProp;
type ChildProps =
  | {
      children?: never;
      render: ReactNode;
    }
  | {
      children: ReactNode;
      render?: never;
    }
  | {
      children?: never;
      render?: never;
      variant: 'icon';
    };
type ButtonProps = ButtonAllowList &
  DSComponent &
  ClickProps &
  ChildProps & {
    variant?: Variants;
    icon?: ReactNode;
    iconName?: IconNames;
    loading?: boolean;
    size?: 'tiny' | 'small' | 'normal';
    label?: string;
  };
export const Button = ({
  sx,
  _sx,
  children,
  render,
  variant = 'primary',
  icon,
  iconName,
  disabled,
  loading,
  onClick,
  to,
  href,
  type,
  size = 'normal',
  ...rest
}: ButtonProps) => {
  const variantSx = variants[variant];
  const { fontSize, ...sizeSx } = sizes[size];

  const isTiny = size === 'tiny';
  const isIcon = variant === 'icon';

  const Component = to ? BoxLink : href ? Anchor : Touchable;
  const props = to
    ? { to }
    : href
    ? {
        href,
        target: '_blank',
      }
    : { onClick, type, disabled: loading || disabled };

  return (
    <Touchable
      as={Component}
      sx={{
        // Reset
        border: 'none',
        background: 'transparent',

        // Common
        lineHeight: 1,
        width: 'fit-content',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'background',
        cursor: disabled || loading ? 'not-allowed' : 'pointer',
        opacity: disabled || loading ? 0.5 : 1,
        transition: 'opacity 300ms ease',

        // Variant & size
        ...sizeSx,
        ...variantSx,
        ...sx,
      }}
      _sx={{
        ':not([disabled]):hover': {
          filter: 'brightness(97.5%)',
        },
        ':not([disabled]):active': {
          filter: 'brightness(95%)',
          transform: 'translateY(1px)',
        },
        ..._sx,
      }}
      {...props}
      {...rest}
    >
      {isIcon || render ? null : (
        <Text
          variant={isTiny ? 'body' : 'punch'}
          sx={{
            fontSize,
            textAlign: 'left',
          }}
        >
          {children}
        </Text>
      )}
      {render}
      {iconName || icon || loading ? (
        <Box
          sx={{
            height: 24,
            width: 24,
            ml: isIcon ? 0 : 4,
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <CircularProgress size={16} color="inherit" />
          ) : iconName ? (
            <Icon
              name={iconName}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ) : (
            icon
          )}
        </Box>
      ) : null}
    </Touchable>
  );
};
