import { useTheme } from '@mui/system';
import { createContext, ReactNode, useContext, useState } from 'react';
import { PageColorVariant } from '../components';

const BackgroundColorContext = createContext<{
  backgroundColor: string;
  pageColorVariant: PageColorVariant;
  setBackgroundColor: (color: string) => void;
  setPageColorVariant: (pageColorVariant: PageColorVariant) => void;
  reset: () => void;
}>({
  backgroundColor: '',
  pageColorVariant: 'light',
  setBackgroundColor: () => undefined,
  setPageColorVariant: () => undefined,
  reset: () => undefined,
});

export const useBackgroundColorContext = () =>
  useContext(BackgroundColorContext);

export const BackgroundColorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const theme = useTheme();

  const getVariantColour = (variant: PageColorVariant) => {
    if (variant === 'light') {
      return theme.palette.common.white;
    }
    if (variant === 'dark') {
      return theme.palette.common.dark;
    }
  };
  const [pageColorVariant, setPageColorVariant] =
    useState<PageColorVariant>('light');
  const [backgroundColor, setBackgroundColor] = useState(
    getVariantColour(pageColorVariant),
  );

  const reset = () => {
    setPageColorVariant('light');
    setBackgroundColor(getVariantColour('light'));
  };

  return (
    <BackgroundColorContext.Provider
      value={{
        backgroundColor,
        setBackgroundColor,
        pageColorVariant,
        setPageColorVariant,
        reset,
      }}
    >
      {children}
    </BackgroundColorContext.Provider>
  );
};
