import ms from 'ms';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeContext } from '../../makeContext';

export type NotificationType = {
  message: ReactNode;
  type: 'success' | 'error';
};

export type NotificationContext = {
  notification: NotificationType | null;
  notifySuccess: (message: ReactNode) => void;
  notifyError: (message: ReactNode) => void;
  clearNotification: () => void;
};

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notification, setNotification] = useState<NotificationType | null>(
    null,
  );
  const location = useLocation();

  const clearNotification = () => setNotification(null);

  const notifyError = (message: ReactNode) =>
    setNotification({
      type: 'error',
      message,
    });

  const notifySuccess = (message: ReactNode) =>
    setNotification({
      type: 'success',
      message,
    });

  useEffect(() => {
    if (!notification) return;

    const timer = setTimeout(() => {
      clearNotification();
    }, ms('5 seconds'));

    return () => clearTimeout(timer);
  }, [notification]);

  useEffect(() => {
    clearNotification();
  }, [location]);

  return (
    <Provider
      value={{ notifyError, notifySuccess, notification, clearNotification }}
    >
      {children}
    </Provider>
  );
};

const { useContext, Provider } = makeContext<NotificationContext>(
  'NotificationContext',
);

export const useNotification = useContext;
