import {
  DSTheme,
  FontSizeChoices,
  SpaceChoices,
  useDSTheme,
} from '@semper/shared-components';
import css, {
  SystemCssProperties,
  SystemStyleObject,
} from '@styled-system/css';
import { ReactNode } from 'react';
import {
  ColorProps,
  FontFamilyProps,
  FontSizeProps,
  ResponsiveValue,
  SpaceProps,
} from 'styled-system';

export type Colors = ResponsiveValue<keyof DSTheme['colors']>;

export type SxType = {
  // Loop through all supported CSS properties
  [K in keyof SystemCssProperties]: K extends keyof Omit<ColorProps, 'opacity'>
    ? Colors // Constrain colours props to only allow theme colours
    : // Constrain all space-using props to only allow theme space values
    K extends keyof SpaceProps
    ? ResponsiveValue<SpaceChoices>
    : // Constrain fontFamily to only our available fonts
    K extends keyof FontFamilyProps
    ? ResponsiveValue<keyof DSTheme['fonts']>
    : // Constrain fontSize to only theme font sizes
    K extends keyof FontSizeProps
    ? ResponsiveValue<FontSizeChoices>
    : // Otherwise allow any appropriate CSS value
      SystemCssProperties[K];
};

export type SxProp = {
  sx?: SxType;
  _sx?: SystemStyleObject;
};

export type DSComponent = SxProp & {
  children?: ReactNode;
};

export const sxFn = (props: SxProp) =>
  css({
    ...props.sx,
    ...props._sx,
  });

// HACK: This fn will get called by `@emotion/styled` with `sx, theme`, but this theme will always be MUI's, which we don't want. See below comments.
export const useEmotionMUIScopeHack = ({ sx = {}, _sx }: SxProp) => {
  // MUI uses emotion under the hood, but treats emotion as a singular instance. Because we have a separate (different) theme from MUI's, we have to do this hack to pass in our correct theme.
  // Storbyook also uses emotion, but they scope their theme succesfully so it doesn't clash with client themes. Super annoying, but we'll just have to live with this while we have MUI in our stack.
  // See the various issues below:
  // https://github.com/storybookjs/storybook/issues/10231
  // https://github.com/mui/material-ui/issues/25852
  // https://stackoverflow.com/questions/72137028/adding-a-mui5-theme-into-an-application-that-has-a-custom-emotion-theme
  const { theme } = useDSTheme();
  return sxFn({ sx, _sx })(theme);
};
