import { createTheme } from '@mui/material';

export const colors = {
  Silver: '#CCCCCC',
  Mercury: '#E5E5E5',
  Boulder: '#757575',
  ElectricViolet: '#6E53F6',
  RoyalBlue: '#5D40F0',
  MountainMeadow: '#21B75D',
  Selago: '#F0EEFE',
  Flamingo: '#EE5938',
  BrickRed: '#D52E4C',
  GreyAAA: '#AAAAAA',
  Concrete: '#F2F2F2',
  GreyF9: '#F9F9F9',
  TulipTree: '#EEBB38',
};

export enum Spacing {
  px4 = '4px',
  px8 = '8px',
  px12 = '12px',
  px16 = '16px',
  px24 = '24px',
  px32 = '32px',
  px48 = '48px',
  px64 = '64px',
  px96 = '96px',
}

export enum SpacingNamed {
  small1 = '4px',
  small2 = '8px',
  small3 = '12px',
  medium1 = '16px',
  medium2 = '24px',
  medium3 = '32px',
  large1 = '48px',
  large2 = '64px',
  large3 = '96px',
}

export enum BorderRadius {
  px4 = '4px',
  px6 = '6px',
  px12 = '12px',
  px24 = '24px',
  px48 = '48px',
}

export enum FontSize {
  px10 = '10px',
  px12 = '12px',
  px14 = '14px',
  px16 = '16px',
  px18 = '18px',
  px20 = '20px',
  px22 = '22px',
  px24 = '24px',
  px40 = '40px',
}

export enum ZIndex {
  OverContent = 2,
  Elevation1 = 10,
  Elevation2 = 20,
  AlwaysVisible = 100,
}

export enum FontWeight {
  w400 = '400',
  w700 = '700',
}

export const fontFamily = ['Aeonik', 'sans-serif'].join(',');

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      // TODO: Move the font-face declarations to DS
      styleOverrides: `
        html {
          -moz-font-feature-settings: 'ss01';
          -webkit-font-feature-settings: 'ss01';
          font-feature-settings: 'ss01';
        }
        body {
          background-color: #fff;
        }
        @font-face {
          font-family: "Aeonik";
          src: local("Aeonik"), url("/fonts/Aeonik-Regular.ttf") format("truetype");
          font-weight: 400;
        }
        @font-face {
          font-family: "Aeonik";
          src: local("Aeonik"), url("/fonts/Aeonik-Medium.ttf") format("truetype");
          font-weight: 500;
        }
        @font-face {
          font-family: "Aeonik";
          src: local("Aeonik"), url("/fonts/Aeonik-Bold.ttf") format("truetype");
          font-weight: 700;
        }
        .MuiInputBase-root {
          transition: all 0.3s ease-in-out;
        }
        .MuiInputBase-root:hover fieldset {
          border-color: ${colors.Silver} !important;
        }
        .MuiInputBase-root fieldset {
          border-color: ${colors.Mercury} !important;
          transition: all 0.3s ease-in-out;
        }
        .MuiInputBase-root.Mui-focused fieldset {
          border-color: ${colors.ElectricViolet} !important;
        }
        .MuiAutocomplete-option {
          padding: ${Spacing.px16} 0;
        }
        .MuiPaper-root.MuiAutocomplete-paper {
          padding: 0;
          border-radius: ${BorderRadius.px12};
          margin-top: ${Spacing.px16};
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        }
        .MuiPaper-root.MuiAutocomplete-paper ul {
          padding: 0;
        }
        .MuiPaper-root.MuiAutocomplete-paper li {
          padding: ${Spacing.px16};
        }
        .MuiAutocomplete-listbox {
          padding: 0;
        }
        .MuiTabs-flexContainer button {
          padding: ${Spacing.px16} 0;
          margin-right: ${Spacing.px32};
        }
        `,
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: FontWeight.w400,
          border: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          paddingTop: 0,
          fontSize: FontSize.px14,
        },
        root: {
          fontWeight: FontWeight.w400,
          border: 'none',
          fontSize: FontSize.px20,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: 4,
          height: 33,
          fontWeight: FontWeight.w700,
          fontSize: FontSize.px12,
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '32px 0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            fontSize: FontSize.px12,
            fontWeight: FontWeight.w400,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: FontSize.px12,
          borderWidth: 1,
          borderRadius: 12,
          borderColor: '#E4E4E4',
        },
        root: {
          fontSize: FontSize.px16,
          fontWeight: FontWeight.w400,
          lineHeight: FontSize.px24,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: FontSize.px14,
          fontWeight: FontWeight.w700,
          textTransform: 'uppercase',
          borderRadius: 12,
          height: 64,
          flexWrap: 'nowrap',
        },
        outlinedSecondary: {
          backgroundColor: 'white',
          color: colors.ElectricViolet,
          border: `1px solid ${colors.ElectricViolet}`,
          ':hover': {
            backgroundColor: colors.ElectricViolet,
            color: 'white',
            border: '1px solid white',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        rounded: {
          borderRadius: 12,
          padding: 16,
          border: `1px solid ${colors.Mercury}`,
          overflow: 'hidden',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          marginLeft: 16,
          fontSize: FontSize.px12,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: { marginLeft: 5 },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 20,
          borderRadius: 12,
          backgroundColor: 'black',
          fontSize: FontSize.px14,
          fontWeight: FontWeight.w400,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: FontSize.px14,
          fontWeight: FontWeight.w400,
          letterSpacing: 0,
        },
        h1: {
          fontSize: FontSize.px40,
          fontWeight: FontWeight.w700,
          letterSpacing: '-0.02em',
          lineHeight: '52px',
        },
        h3: {
          fontSize: FontSize.px40,
          fontWeight: FontWeight.w400,
        },
        h4: {
          fontSize: FontSize.px22,
          fontWeight: FontWeight.w700,
          color: '#866CE6',
        },
        h6: {
          fontSize: FontSize.px24,
          fontWeight: FontWeight.w700,
        },
        subtitle1: {
          fontWeight: FontWeight.w400,
          fontSize: FontSize.px20,
        },
        subtitle2: {
          fontWeight: FontWeight.w400,
          fontSize: FontSize.px14,
          color: colors.Boulder,
          lineHeight: '17px',
        },
        overline: {
          fontSize: FontSize.px14,
          color: colors.Boulder,
          textTransform: 'capitalize',
        },
        caption: {
          fontWeight: FontWeight.w400,
          fontSize: FontSize.px14,
          color: colors.Boulder,
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.ElectricViolet,
      light: colors.Selago,
      dark: colors.RoyalBlue,
    },
    success: {
      main: colors.MountainMeadow,
      light: '#E9F8EF',
    },
    error: {
      main: colors.BrickRed,
      light: '#FEEFEC',
      dark: colors.BrickRed,
    },
    text: {
      primary: '#000000',
      secondary: colors.Boulder,
      disabled: colors.Silver,
    },
    divider: colors.Mercury,
    grey: {
      A100: colors.Concrete,
      A200: colors.Mercury,
      A400: colors.GreyAAA,
      100: 'rgba(255,255,255,0.25)',
      500: 'rgba(255,255,255,0.5)',
    },
  },
  typography: {
    fontFamily,
  },
});

export const palette = theme.palette;
