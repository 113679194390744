import { Box } from '../Box';
import { DSComponent } from '../SemperDS';
import { ReactComponent as arrow } from './icons/arrow.svg';
import { ReactComponent as check } from './icons/check.svg';
import { ReactComponent as chevron } from './icons/chevron.svg';
import { ReactComponent as cog } from './icons/cog.svg';
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as documents } from './icons/documents.svg';
import { ReactComponent as email } from './icons/email.svg';
import { ReactComponent as emailPassword } from './icons/emailpassword.svg';
import { ReactComponent as external } from './icons/external.svg';
import { ReactComponent as face } from './icons/face.svg';
import { ReactComponent as faceID } from './icons/faceid.svg';
import { ReactComponent as faq } from './icons/faq.svg';
import { ReactComponent as file } from './icons/file.svg';
import { ReactComponent as fingerprint } from './icons/fingerprint.svg';
import { ReactComponent as list } from './icons/list.svg';
import { ReactComponent as SemperLogo } from './icons/logo.svg';
import { ReactComponent as minus } from './icons/minus.svg';
import { ReactComponent as phone } from './icons/phone.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as receipt } from './icons/receipt.svg';
import { ReactComponent as sign } from './icons/sign.svg';
import { ReactComponent as sunset } from './icons/sunset.svg';
import { ReactComponent as timeline } from './icons/timeline.svg';
import { ReactComponent as zip } from './icons/zip.svg';

// Ideally we want all these icons to be tree-shakeable so we're not importing
// masses of SVG in every bundle. Need to figure that out...

const names = {
  arrow,
  plus,
  minus,
  email,
  emailPassword,
  faceID,
  fingerprint,
  file,
  phone,
  cross,
  check,
  external,
  chevron,
  documents,
  zip,
  list,
  timeline,
  faq,
  face,
  cog,
  sign,
  receipt,
  sunset,
};
export type IconNames = keyof typeof names;
export const __variantsForStorybook = Object.keys(names) as IconNames[];

export const Icon = ({
  sx,
  name,
}: DSComponent & {
  name: IconNames;
}) => {
  const NamedIcon = names[name];
  return (
    <Box
      sx={{
        width: '24px',
        ...sx,
      }}
      _sx={{
        svg: {
          width: '100%',
          height: '100%',
        },
      }}
    >
      <NamedIcon />
    </Box>
  );
};

export const BiometricsIcon = () => {
  const mightBeiPhone = navigator.userAgent.match(/iPhone/);
  return <Icon name={mightBeiPhone ? 'faceID' : 'fingerprint'} />;
};

export const Logo = ({ sx, _sx }: DSComponent) => (
  <Box
    sx={sx}
    _sx={{
      width: 80,
      ..._sx,
      svg: {
        width: '100%',
        height: '100%',
      },
    }}
  >
    <SemperLogo />
  </Box>
);
