import { useDebouncedValue } from '@mantine/hooks';
import { FieldValidator, useField } from 'formik';
import {
  ChangeEventHandler,
  ElementType,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { Box, Input } from '../Box';
import { Checkbox } from '../Checkbox';
import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import { Select, SelectProps } from '../Select';
import { DSComponent } from '../SemperDS';
import { Text } from '../Text';
import { useUserAction } from '../Tracking';

type FieldInputProps = {
  name: string;
  label?: ReactNode;
  error?: boolean;
  disabled?: boolean;
};
type FieldProps<T = Record<string, unknown>> = T &
  FieldInputProps &
  DSComponent & {
    validate?: FieldValidator;
  };
type Opts = {
  type?: 'checkbox' | 'radio';
  directValueAPI?: boolean;
};

const makeFieldComponent = <T,>(InputComponent: ElementType, opts?: Opts) => {
  // TODO: Field registration for scroll-to-error
  const FormField = ({ name, sx, validate, ...rest }: FieldProps<T>) => {
    const focusAction = useUserAction('focus');
    const errorAction = useUserAction('field-error');
    const [field, { error, touched }, { setValue }] = useField({
      name,
      validate,
      ...opts,
    });
    const inputProps = {
      ...field,
      ...rest,
      onFocus: () => focusAction({ name }),
      onChange: opts?.directValueAPI ? setValue : field.onChange,
    };

    useEffect(() => {
      if (touched && error) {
        errorAction({ name, error });
      }
    }, [touched, error]);

    return (
      <Box sx={{ width: '100%', minWidth: 0, ...sx }}>
        <InputComponent error={Boolean(touched && error)} {...inputProps} />
        {touched && error ? (
          <Text
            color="error100"
            variant="caption"
            sx={{
              mt: 2,
              mb: 1,
              pl: 2,
              display: 'block',
            }}
          >
            {error}
          </Text>
        ) : null}
      </Box>
    );
  };
  // @ts-expect-error `displayName` does exist
  FormField.displayName = `Field.${InputComponent.displayName || 'Unknown'}`;
  return FormField;
};

const Label = ({ children, error, sx }: DSComponent & { error?: boolean }) => (
  <Text
    variant="small"
    sx={{
      mb: 1,
      color: error ? 'error100' : 'text',
      fontWeight: 'medium',
      ...sx,
    }}
  >
    {children}
  </Text>
);

const Control = ({
  children,
  sx,
  disabled,
}: { disabled?: boolean } & DSComponent) => (
  <Box
    as="label"
    sx={{
      flexDirection: 'column',
      width: '100%',
      opacity: disabled ? 0.7 : 1,
      cursor: disabled ? null : 'pointer',
      ...sx,
    }}
  >
    {children}
  </Box>
);

const TextInput = ({
  error,
  disabled,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Input
    sx={{
      appearance: 'none',
      boxSizing: 'content-box',
      border: '1px solid',
      borderColor: error ? 'error100' : 'grey20',
      borderRadius: 12,
      bg: 'background',
      py: 5,
      px: 5,
      color: error ? 'error100' : 'text',
      outlineColor: 'primary',
      ...sx,
    }}
    _sx={{
      fontFamily: "'Aeonik', sans-serif",
      fontSize: '16px',
      cursor: disabled ? 'not-allowed' : 'text',
    }}
    disabled={disabled}
    {...props}
  />
);

const DateField = ({
  disabled,
  label,
  error,
  sx,
  value,
  onChange,
  name,
  onBlur,
}: FieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (val: string) => void;
  }) => {
  if (typeof value !== 'string') {
    throw new Error('Only strings supported');
  }
  const [val, setVal] = useState(value);
  const [yyyy = '', mm = '', dd = ''] = val.split('-');
  const handleDay: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${yyyy}-${mm}-${e.target.value}`);
  const handleMonth: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${yyyy}-${e.target.value}-${dd}`);
  const handleYear: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${e.target.value}-${mm}-${dd}`);

  const [blurEvent, setBlurEvent] =
    useState<FocusEvent<HTMLInputElement> | null>(null);

  const handleFocus = () => {
    setBlurEvent(null);
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    setBlurEvent(e);
  };

  const [debouncedEvent] = useDebouncedValue(blurEvent, 50);

  useEffect(() => {
    if (debouncedEvent) onBlur?.(debouncedEvent);
  }, [debouncedEvent]);

  useEffect(() => {
    if (val && onChange) {
      onChange(val);
    }
  }, [val]);

  return (
    <Control disabled={disabled} sx={sx}>
      <Label error={error}>{label}</Label>
      <Box
        sx={{
          width: '100%',
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? null : 'pointer',
          flexDirection: 'row',
          gap: 3,
          ...sx,
        }}
      >
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          onChange={handleDay}
          value={dd}
          inputMode="numeric"
          placeholder="dd"
          sx={{
            minWidth: 0,
            flex: '2 1 0',
          }}
        />
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          inputMode="numeric"
          onChange={handleMonth}
          value={mm}
          placeholder="mm"
          sx={{
            minWidth: 0,
            flex: '2 1 0',
          }}
        />
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          inputMode="numeric"
          onChange={handleYear}
          value={yyyy}
          placeholder="yyyy"
          sx={{
            minWidth: 0,
            flex: '3 1 0',
          }}
        />
      </Box>
    </Control>
  );
};

const CustomInput = ({
  disabled,
  label,
  error,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Control disabled={disabled} sx={sx}>
    <Label error={error}>{label}</Label>
    <TextInput error={error} disabled={disabled} {...props} />
  </Control>
);

const CustomTextarea = ({
  disabled,
  label,
  error,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Control disabled={disabled} sx={sx}>
    <Label error={error}>{label}</Label>
    <TextInput disabled={disabled} as="textarea" {...props} />
  </Control>
);

const LabelledSelect = ({
  label,
  disabled,
  sx,
  ...rest
}: SelectProps & FieldInputProps & SelectHTMLAttributes<HTMLSelectElement>) => (
  <Control disabled={disabled} sx={sx}>
    <Label error={rest.error}>{label}</Label>
    <Select disabled={disabled} {...rest} />
  </Control>
);

export const _forStoryBook = {
  Input: CustomInput,
  DateField,
};

export const Fields = {
  Label,
  Input: makeFieldComponent<InputHTMLAttributes<HTMLInputElement>>(CustomInput),
  Textarea:
    makeFieldComponent<TextareaHTMLAttributes<HTMLTextAreaElement>>(
      CustomTextarea,
    ),
  Checkbox: makeFieldComponent(Checkbox, { type: 'checkbox' }),
  Select: makeFieldComponent<SelectProps>(LabelledSelect),
  RadioGroup: makeFieldComponent<Omit<RadioGroupProps, 'onChange'>>(RadioGroup),
  Date: makeFieldComponent(DateField, { directValueAPI: true }),
};
