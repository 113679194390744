import styled from '@emotion/styled';
import { Link, LinkProps } from 'react-router-dom';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const UnstyledLink = ({ children, ...rest }: LinkProps) => {
  return <StyledLink {...rest}>{children}</StyledLink>;
};

export default UnstyledLink;

// These are used in the storybook and tests
export const exampleArgs: LinkProps = {
  children: <b>This should be a link</b>,
  to: '/',
};
