import { Chip, ChipProps, Typography } from '@mui/material';
import { BorderRadius } from '@semper/shared-components';
import { LiquidityEventStatus } from '../../../../investor/src/generated/graphql';

type props = {
  status: LiquidityEventStatus;
  fgColor: string;
};

export const StatusChip = ({ status, fgColor, ...rest }: props & ChipProps) => {
  const isClosed = status === LiquidityEventStatus.Closed;
  return (
    <Chip
      label={
        <Typography
          variant="subtitle1"
          lineHeight="33px"
          fontSize={[14, 18]}
          fontWeight="bold"
        >
          {status.toLocaleLowerCase()}
        </Typography>
      }
      className="status-chip"
      style={{
        backgroundColor: isClosed ? 'black' : 'white',
        color: isClosed ? 'white' : fgColor,
        borderRadius: BorderRadius.px48,
      }}
      {...rest}
    />
  );
};

export const statusChipExampleArgs: props = {
  status: LiquidityEventStatus.Live,
  fgColor: 'black',
};
