import { keyframes } from '@emotion/react';
import { Box, DSComponent, Text as DSText } from '@semper/shared-components';
import { Fragment } from 'react';
import { TextProps } from '../Text/Text';

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;

  }
  100% {
    opacity: 1;
  }
`;

const Rect = ({
  width,
  height,
  sx,
}: DSComponent & {
  width: string;
  height: string;
}) => (
  <Box
    sx={{
      width,
      height,
      borderRadius: '8px',
      animation: `${pulse} 1s ease infinite`,
      ...sx,
    }}
    _sx={{
      backgroundColor: 'rgba(0,0,0,0.05)',
    }}
  />
);

const Text = ({
  length,
  sx,
  ...rest
}: TextProps & {
  length: number | string;
}) => (
  <DSText
    sx={{
      ...sx,
      borderRadius: '8px',
      width: 'fit-content',
      animation: `${pulse} 1s ease infinite`,
    }}
    _sx={{
      backgroundColor: 'rgba(0,0,0,0.05)',
    }}
    {...rest}
  >
    {new Array(Number(length)).fill('').map((_, i) => (
      <Fragment key={i}>&emsp;</Fragment>
    ))}
  </DSText>
);

export const Skeleton = {
  Rect,
  Text,
};
